
import React from "react"
import CallToAction from "src/components/call-to-action/CallToAction"
import RichText from "src/components/rich-text/RichText"
import { Heading, Only, Section } from "src/utils"
import { Step } from "./StepsModule"
import s from "./StepsModule.module.scss"
import cn from "classnames"

export default function Steps({ steps, className }: {steps: Partial<Step>[], className?: string}): JSX.Element {
    return (
        <ul className={cn(s["steps"], className)}>
            {(steps || []).map((step, index) => {
                return (
                    <li
                        key={step?._key + "" + index}
                        className={cn(s["item"], { [s["item--wide-number"]]: `${step.circleText}`.length > 1 })}
                    >
                        <Section>
                            <div className={cn(s["item__number"])}>
                                {step.circleText || (index + 1)}
                            </div>

                            <div className={cn(s["item__text"])}>
                                <Heading className={cn("label-200")}>{step.title}</Heading>
                                <RichText blocks={step.text} className="paragraph-100" />

                                <Only if={step?.cta}>
                                    <CallToAction className={cn(s["item__cta"], s[`item__cta--${step?.cta?.style}`])} {...step?.cta} />
                                </Only>
                            </div>
                        </Section>
                    </li>
                )
            })}
        </ul>
    )
}
