import React, { useEffect, useState } from "react"
// import s from "./EmbeddedContent.module.scss"
// import cn from "classnames"
import { useQRCode } from "next-qrcode"
import { createDeferredDownloadLink } from "src/lib/oneclick/util"
import { useSettings } from "src/layout/page/settingsContext"

export const QRCode = (): JSX.Element => {
    const { lang, segment } = useSettings()
    const Canvas = useQRCode().Canvas
    // const { ids, source, campaign } = useOneclickTracking()
    const [qrLink, setQrLink] = useState(createDeferredDownloadLink({ ids: {}, linkSource: "qr", lang, segment }))

    useEffect(() => {
        setQrLink(window.location.href)
    })

    return <Canvas
        text={qrLink}
        options={{
            width: 500,
            errorCorrectionLevel: "L",
            margin: 2,
        }}
    />

}
