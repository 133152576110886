import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"
import s from "./RollingText.module.scss"

interface RollingTextProps {
    rollingElements?: JSX.Element[]
    className?: string
    forcedActiveIndex?: number
    intervalDuration?: number
    animation?: "slide-down" | "slide-right" | "roll-up" | "roll-down"
}

export const RollingText = ({
    rollingElements,
    className,
    forcedActiveIndex,
    animation = "slide-down",
    intervalDuration = 3000,
}: RollingTextProps): JSX.Element => {
    const [activeIndex, setActiveIndex] = useState(typeof forcedActiveIndex === "number" ? forcedActiveIndex : 0)
    const containerRef = useRef<HTMLDivElement>()
    const [containerHeight, setContainerHeight] = useState<number>()
    const [hasMadeOneInterval, setHasMadeOneInterval] = useState(false)

    useEffect(() => {
        if (typeof forcedActiveIndex === "number") {
            setActiveIndex(forcedActiveIndex)
        }
    }, [forcedActiveIndex])

    useEffect(() => {
        if (containerRef?.current) {
            const children: HTMLDivElement[] = Array.prototype.slice.call(containerRef?.current.children).reverse()
            const heighestElement = Math.max(...children.map(ele => ele.offsetHeight))
            setContainerHeight(heighestElement)
        }
    }, [rollingElements, containerRef])

    useEffect(() => {
        if (typeof forcedActiveIndex === "number")
            return

        const interval = setInterval(() => {
            if (hasMadeOneInterval === false)
                setHasMadeOneInterval(true)
            setActiveIndex((lastActiveIndex) => lastActiveIndex === rollingElements.length - 1 ? 0 : lastActiveIndex + 1)
        }, intervalDuration)

        return () => clearInterval(interval)
    }, [])

    return (
        <div data-activeindex={activeIndex} className={cn(className, s["rolling-text"], s[`rolling-text-animation-${animation}`])} style={{ height: containerHeight + "px" }} ref={containerRef}>
            {rollingElements && rollingElements.map((ele, i) => (
                React.cloneElement(ele, { key: i, className: cn(s["rolling-text__item"], activeIndex === i ? "animate-in" : (hasMadeOneInterval ? "animate-out" : s["rolling-text__item--hidden"])) })
            ))}
        </div>
    )
}
