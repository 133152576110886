import React, { useEffect } from "react"
import s from "./InterestCalculator.module.scss"
import cn from "classnames"
import RichText from "../rich-text/RichText"
import { useSettings } from "src/layout/page/settingsContext"
import { languageMap } from "src/sanity/languages"
import { getTranslation } from "src/lib/translations"

export const InterestCalculator = ({
    currency,
    interestRate,
    monthlyReturnText,
    periodicalAmount,
    startingAmount,
    subtitle,
    timeHorizon,
    title,
}: Sanity.Schema.InterestCalculator): JSX.Element => {
    const { lang } = useSettings()
    const t = getTranslation("misc", lang)
    // const [yearlyReturn, setYearlyReturn] = React.useState(0)
    const [monthlyReturn, setMonthlyReturn] = React.useState(0)
    const [selectedInterestRateIndex, setSelectedInterestRateIndex] = React.useState(interestRate.length - 1)
    const [totalReturn, setTotalReturn] = React.useState(0)
    const [countingReturn, setCountingReturn] = React.useState(0)
    const [selectedStartingAmount, setSelectedStartingAmount] = React.useState(startingAmount.default || startingAmount.max / 2)
    const [selectedPeriodicalAmount, setSelectedPeriodicalAmount] = React.useState(periodicalAmount.default || periodicalAmount.max / 2)
    const [selectedTimeHorizon, setSelectedTimeHorizon] = React.useState(timeHorizon.default || timeHorizon.max / 2)

    const currencyFormatter = new Intl.NumberFormat(languageMap[lang].intlLocale, { style: "currency", useGrouping: true, maximumFractionDigits: 0, currency: currency, currencyDisplay: "narrowSymbol" })

    useEffect(() => {
        const monthlyInterestRate = interestRate[selectedInterestRateIndex].interestRate / 12
        let returnAmount = selectedStartingAmount
        const monthlyReturns = []
        for (let month = 1; month <= selectedTimeHorizon * 12; month++) {
            // Add the monthly interest return to the array
            const interestThisMonth = returnAmount * (monthlyInterestRate/100)
            monthlyReturns.push(interestThisMonth)
            // Add the periodical deposits
            returnAmount += (periodicalAmount.period === "monthly" ? selectedPeriodicalAmount : selectedPeriodicalAmount / 12)
            // Add the interest
            returnAmount += interestThisMonth
        }

        const averageMonthlyReturn = monthlyReturns.reduce((a, b) => a + b, 0) / monthlyReturns.length
        setMonthlyReturn(averageMonthlyReturn)

        setTotalReturn(returnAmount)
    }, [selectedStartingAmount, selectedPeriodicalAmount, selectedTimeHorizon, selectedInterestRateIndex])

    useEffect(() => {
        let currentNumber = countingReturn
        const targetNumber = Number(totalReturn) // Change this to your desired target number
        const duration = 1000 // Animation duration in milliseconds
        const step = Math.abs(currentNumber - targetNumber) / (duration / 60) // Calculate step size based on duration

        function updateNumber() {
            if (currentNumber < targetNumber) {
                currentNumber += step
                if (currentNumber > targetNumber) {
                    currentNumber = targetNumber
                }
                setCountingReturn(Number(currentNumber.toFixed(0)))
                requestAnimationFrame(updateNumber)
            }
            if (currentNumber > targetNumber) {
                currentNumber -= step
                if (currentNumber < targetNumber) {
                    currentNumber = targetNumber
                }
                setCountingReturn(Number(currentNumber.toFixed(0)))
                requestAnimationFrame(updateNumber)
            }
        }

        updateNumber()
    }, [totalReturn])

    return (
        <div className={cn(s["interest-calculator"], "text-black")}>
            {/* Subtitle */}
            {subtitle &&  <h4 className={cn(s["collage-module__box__sub-title"], "paragraph-200", "mb-small")}>{subtitle}</h4> }

            {/* Title */}
            {title && (
                <RichText blocks={title} className={cn("heading-200")} />
            )}

            {/* Calculated total return, formatted in currency format */}
            {/* <div className="heading-300 colored">{currencyFormatter.format(totalReturn)}</div> */}
            <div className="heading-300 colored">{currencyFormatter.format(countingReturn)}</div>

            {/* Time horizon */}

            {/* Periodical amount */}
            {monthlyReturnText && (
                <p className="paragraph-100">{monthlyReturnText} {currencyFormatter.format(monthlyReturn)}</p>
            )}

            <div style={{ height: "40px" }}></div>

            {/* Sliders */}
            <Slider {...startingAmount} value={selectedStartingAmount} valueSetter={setSelectedStartingAmount} formatter={currencyFormatter.format}/>
            <Slider {...periodicalAmount} value={selectedPeriodicalAmount} valueSetter={setSelectedPeriodicalAmount} formatter={currencyFormatter.format} />
            <Slider {...timeHorizon} value={selectedTimeHorizon} valueSetter={setSelectedTimeHorizon} formatter={(value) => `${value} ${t.year.toLowerCase()}`} />

            {interestRate.length > 1 && (
                <Slider title={t.interestRate} min={0} max={interestRate.length - 1} value={selectedInterestRateIndex} valueSetter={setSelectedInterestRateIndex} formatter={(index) => `${interestRate[index].interestName}: ${interestRate[index].interestRate}%`} />
            )}

        </div>
    )
}

interface SliderProps {
    title?: string
    min?: number
    max?: number
    value?: number
    formatter?: (value: number) => string
    valueSetter: (value: number) => void
}

function Slider({ title, min, max, value, valueSetter, formatter }: SliderProps) {
    const variableReasonableStep = Math.max(1, (max) / 100)
    // const [value, setValue] = React.useState(rest?.default || min)
    const [backgroundSize, setBackgroundSize] = React.useState("0%")

    useEffect(() => {
        setBackgroundSize(`${(((value - min) / (max - min)) * 100)}% 100%`)
    }, [value, min, max])

    return (
        <div className={cn(s["interest-calculator__slider"])}>
            <div className={cn(s["interest-calculator__slider__top-row"])}>
                <h3>{title}</h3>
                <div className="label-200">{formatter ? formatter(value) : value}</div>
            </div>
            <input type="range" min={min} max={max} value={value} step={variableReasonableStep} onChange={(e) => valueSetter(Number(e.target.value))} style={{ backgroundSize }} />
        </div>
    )
}
