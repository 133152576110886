import React from "react"
import CustomComponentModule, { CustomComponentProps } from "src/modules/custom-component-module/CustomComponent"
import StepsModule, { StepsModuleProps } from "src/modules/steps-module/StepsModule"
// import s from "./EmbeddedContent.module.scss"
// import cn from "classnames"
import { InterestCalculator } from "./InterestCalculator"
import { QRCode } from "./QRCode"

interface EmbeddedContentProps {
    object: Sanity.Schema.InlineCollageModule["box1"]["embeddedContent"][number]
}


export const EmbeddedContent = ({
    object,
}: EmbeddedContentProps): JSX.Element => {

    switch (object._type) {
        case "interestCalculator":
            return <InterestCalculator {...object} />
        case "qr":
            return <QRCode />
        case "inlineStepsModule":
            return <StepsModule {...(object as unknown as StepsModuleProps)} hasBackgroundColor={false} />
        case "inlineCustomComponentModule":
            return <CustomComponentModule {...object as unknown as CustomComponentProps} parentHasContainer={true} />
        default:
            return <></>
    }
}
