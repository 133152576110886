import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"
import s from "./BasicImage.module.scss"

interface BasicImageProps {
    className?: string
    sizes: string[]
    src: string
    alt: string
    srcSet?: string[]
    placeholder: string
    lazy?: boolean
    aspectRatio?: number
    onLoaded?: () => void
}

export function BasicImage({
    className,
    sizes = [],
    src,
    srcSet = [],
    alt = "",
    placeholder,
    lazy = true,
    aspectRatio = null,
    onLoaded,
}: BasicImageProps): JSX.Element {
    const [loaded, setLoaded] = useState(false)
    const image = useRef<HTMLImageElement>()

    const onLoadHandler = () => {
        setLoaded(true)
        if(onLoaded) {
            onLoaded()
        }
    }

    useEffect(() => {
        if (image.current?.complete) {
            onLoadHandler()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <img
            className={cn(className, s["basic-image"])}
            ref={image}
            onLoad={ onLoadHandler }
            loading={lazy ? "lazy" : undefined}
            alt={alt}
            src={src}
            srcSet={srcSet?.join(", ")}
            sizes={sizes.join(", ")}
            style={{
                backgroundImage: !loaded && placeholder ? `url(${placeholder})` : undefined,
                filter: loaded ? undefined : "blur(1em)",
                aspectRatio,
                width: src && src.includes(".svg") ? "100%" : null,
            }}
            aria-hidden
        />
    )
}
